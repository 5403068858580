import {
  Box,
  Button,
  CardContent,
  Chip,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ReusableFormImportOrdersByDataSDD from "../../../components/ReusableFormImportOrdersByDataSDD/ReusableFormImportOrdersByDataSDD";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { Card } from "semantic-ui-react";
import { green } from "@mui/material/colors";
import EditableOrderGrid from "./EditableOrderGrid";
import axios from "axios";
import FormData from "form-data";
import fs from "fs";
import * as XLSX from "xlsx";
import path from "path";
import ReusableFormImportOrdersByDataSDDFalabella from "../../../components/ReusableFormImportOrdersByDataSDD/ReusableFormImportOrdersByDataSDDFalabella";
import EditableOrderGridFalabella from "./EditableOrderGridFalabella";

const CreateOrderMasive = ({ userDataContext, companiesData }) => {
  const [openImportDataSDD, setOpenImportDataSDD] = useState(false);

  const [openImportDataSDDFalabella, setOpenImportDataSDDFalabella] =
    useState(false);

  const [prefix, setPrefix] = useState("");
  const [companyData, setCompanyData] = useState(null);
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null); // Cambié el estado a singular para manejar un solo objeto

  const [newOrdersData, setNewOrdersData] = useState([]);
  const [errorOrederData, setErrorsOrdersData] = useState([]);
  const [mode, setMode] = useState(null);

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [recentCreated, setRecentCreated] = useState(false);

  const [readyToLaucher, setReadyToLaucher] = useState(false);
  const [contextOrder, setContextOrder] = useState({
    companyID: "",
    token: "",
  });

  useEffect(() => {}, [readyToLaucher]);

  useEffect(() => {
    if (userDataContext && companiesData) {
      setFilteredCompanies(companiesData);
    }
  }, [userDataContext, companiesData]);

  const updateContextOrder = async (data) => {
    setContextOrder((prevState) => ({
      ...prevState,
      companyID: data.integrationServicesBicciID,
      token: data.token,
    }));
  };

  const handleChange = async (e) => {
    const companyDataReq = filteredCompanies.find(
      (company) => company.integrationServicesBicciID === e.target.value
    );

    await handleReset();
    if (companyDataReq && companyDataReq.prefix) {
      setCompanyData(companyDataReq);
      setSelectedCompany(companyDataReq); // Guardamos el objeto completo
      setPrefix(companyDataReq.prefix);
      updateContextOrder(companyDataReq);
    } else {
      console.log("No se encuentra empresa o prefijo", companyDataReq);
    }
  };

  const UploadDocumentsByDataSDD = () => {
    setOpenImportDataSDD(true);
  };

  const UploadDocumentsByDataSDDFalabella = () => {
    setOpenImportDataSDDFalabella(true);
  };

  const handleReset = async () => {
    setOpenImportDataSDD(false);

    setPrefix("");
    setCompanyData(null);

    setSelectedCompany(null);
    setNewOrdersData([]);
    setErrorsOrdersData([]);
    setMode(null);
    setReadyToLaucher(false);
    setContextOrder({
      companyID: "",
      token: "",
    });
    setRecentCreated(false);
  };

  const returnDocumentData = (data, errors, mode) => {
    setNewOrdersData(data);
    setErrorsOrdersData(errors);
    setMode(mode);
    if (data.length > 0 && errors.length === 0) {
      setReadyToLaucher(true);
    }
  };

  const returnDocumentDataFalabella = (data, errors, mode) => {
    setNewOrdersData(data);
    setErrorsOrdersData(errors);
    setMode(mode);
    if (data.length > 0 && errors.length === 0) {
      setReadyToLaucher(true);
    }
  };

  const handleOnSave = (data, errors) => {
    setNewOrdersData(data);
    setErrorsOrdersData(errors);
    if (data.length > 0 && errors.length === 0) {
      setReadyToLaucher(true);
    }
  };

  const handleOnSaveFalabella = (data, errors) => {
    setNewOrdersData(data);
    setErrorsOrdersData(errors);
    if (data.length > 0 && errors.length === 0) {
      setReadyToLaucher(true);
    }
  };

  const cerateOrderDeliveryApi = async (newOrdersData) => {
    setLoading(true);
    setErrorMessage("");
    try {
      // Crear un nuevo libro de trabajo
      const workbook = XLSX.utils.book_new();

      // Convertir el JSON a una hoja de cálculo
      const worksheet = XLSX.utils.json_to_sheet(newOrdersData);

      // Añadir la hoja de cálculo al libro de trabajo
      XLSX.utils.book_append_sheet(workbook, worksheet, "DATA");

      // Convertir el libro de trabajo a un archivo binario (array buffer)
      const excelData = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      // Crear un Blob a partir del array buffer
      const blob = new Blob([excelData], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // Crear form-data y adjuntar el archivo Excel
      let data = new FormData();
      data.append("file", blob, "orders_data.xlsx");

      // Configuración de la solicitud
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: process.env.REACT_APP_BICCIXPRESS_IMPORT_ORDERS,
        headers: {
          companyID: contextOrder.companyID,
          Authorization: `Bearer ${contextOrder.token}`,
        },
        data: data,
      };

      // Realizar la solicitud con axios
      axios
        .request(config)
        .then((response) => {
          setRecentCreated(true);
          setLoading(false);
        })
        .catch((error) => {
          console.log("Error durante la solicitud:", error);
          if (error.response) {
            console.log("Response data:", error.response.data);
            console.log("Response status:", error.response.status);
            setLoading(false);
            setErrorMessage(
              `Error: ${error.response.status} - ${error.response.data}`
            );
          } else if (error.request) {
            setLoading(false);
            console.log(
              "Request made but no response received:",
              error.request
            );
            setErrorMessage("No se recibió respuesta del servidor.");
          } else {
            setLoading(false);
            console.log("Error message:", error.message);
            setErrorMessage(error.message);
          }
        });

      // axios
      //   .get("https://jsonplaceholder.typicode.com/todos/1")
      //   .then((response) => console.log(response))
      //   .catch((error) => console.log(error));
      //const response = { data: { status: "ok" } };
      // Procesar la respuesta exitosa
    } catch (error) {
      // Manejar errores
      console.log("Error durante la solicitud:", error);
    }
  };

  const cerateOrderDeliveryApiFalabellaOrders = async (newOrdersData) => {
    setLoading(true);
    setErrorMessage("");
    try {
      const workbook = XLSX.utils.book_new();

      const worksheet = XLSX.utils.json_to_sheet(newOrdersData);

      XLSX.utils.book_append_sheet(workbook, worksheet, "DATA");

      const excelData = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const blob = new Blob([excelData], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      let data = new FormData();

      data.append("file", blob, "REPORTE_BICCI_EXPRESS.xlsx");

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        timeout: 120000,
        url: process.env.REACT_APP_BICCIXPRESS_IMPORT_ORDERS_OLD_PROXI, //process.env.REACT_APP_BICCI_API_IMPORT_ORDERS_PRO,
        headers: {
          companyID: contextOrder.companyID,
        },
        data: data,
      };

      // Realizar la solicitud con axios
      axios
        .request(config)
        .then((response) => {
          console.log(response);
          setRecentCreated(true);
          setLoading(false);
        })
        .catch((error) => {
          console.log("Error durante la solicitud:", error);
          if (error.response) {
            console.log("Response data:", error.response.data);
            console.log("Response status:", error.response.status);
            setLoading(false);
            setErrorMessage(
              `Error: ${error.response.status} - ${error.response.data}`
            );
          } else if (error.request) {
            setLoading(false);
            console.log(
              "Request made but no response received:",
              error.request
            );
            setErrorMessage("No se recibió respuesta del servidor.");
          } else {
            setLoading(false);
            console.log("Error message:", error.message);
            setErrorMessage(error.message);
          }
          setLoading(false);
        });
    } catch (error) {
      // Manejar errores
      console.log("Error durante la solicitud:", error);
      setLoading(false);
      setErrorMessage("Ocurrió un error durante el proceso.");
    }
  };

  return (
    <div>
      <Grid
        item
        sx={{
          width: {
            xs: "100%",
            sm: "100%",
            md: "100%",
            lg: "100%",
          },
        }}
      >
        <Grid item xs={12} md={12}>
          <Box sx={{ width: "100%", p: 1, m: 2 }}>
            <Typography variant="body" sx={{ margin: "10px" }}>
              Búsqueda de empresas
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                marginTop: "5px",
              }}
            >
              <Grid>
                {filteredCompanies ? (
                  <FormControl
                    fullWidth
                    sx={{
                      minWidth: "350px",
                      maxWidth: "49%",
                      margin: "3px",
                      backgroundColor: "#FFF",
                    }}
                  >
                    <InputLabel
                      id="company-select-label"
                      sx={{
                        backgroundColor: "#FFF",
                        padding: "0px",
                      }}
                    >
                      Empresas
                    </InputLabel>
                    <Select
                      labelId="company-select-label"
                      id="company-select"
                      value={
                        selectedCompany
                          ? selectedCompany.integrationServicesBicciID
                          : ""
                      } // Se asegura de que el valor esté correctamente ligado
                      onChange={handleChange}
                      renderValue={(selected) =>
                        selectedCompany ? (
                          <Chip label={selectedCompany.name} />
                        ) : (
                          "Seleccione una empresa"
                        )
                      }
                    >
                      {filteredCompanies
                        .filter(
                          (company) =>
                            company.integrationServicesBicciID !== undefined
                        )
                        .map((company) => (
                          <MenuItem
                            key={company.integrationServicesBicciID}
                            value={company.integrationServicesBicciID}
                          >
                            {company.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                ) : null}
              </Grid>
              {companiesData && companyData && prefix ? (
                <>
                  {readyToLaucher === false ? (
                    <>
                      {companyData.token ? (
                        <Button
                          variant="outline"
                          startIcon={<FileUploadIcon />}
                          onClick={() => UploadDocumentsByDataSDD(true)}
                          sx={{
                            color: "#fff",
                            fontSize: "1.5em",
                            backgroundColor: "#5160fa ",
                            fontWeight: "400",
                            "&:hover": {
                              opacity: 0.8,
                              backgroundColor: "#5160fa",
                            },
                          }} // margen inferior para separar del DataGrid
                        >
                          Subir documento DATA (Carga masiva)
                        </Button>
                      ) : (
                        <>
                          {companyData.name !== "Falabella" ? null : (
                            <Button
                              variant="outline"
                              startIcon={<FileUploadIcon />}
                              onClick={() =>
                                UploadDocumentsByDataSDDFalabella(true)
                              }
                              sx={{
                                color: "#fff",
                                fontSize: "1.5em",
                                backgroundColor: "#ffae00 ",
                                fontWeight: "400",
                                "&:hover": {
                                  opacity: 0.8,
                                  backgroundColor: "#ffae00",
                                },
                              }} // margen inferior para separar del DataGrid
                            >
                              Subir documento DATA SDD (Carga masiva falabella)
                            </Button>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {!recentCreated ? (
                        <>
                          {companyData.name !== "Falabella" ? (
                            <Button
                              variant="outline"
                              startIcon={<FileUploadIcon />}
                              onClick={() =>
                                cerateOrderDeliveryApi(newOrdersData)
                              }
                              sx={{
                                color: "#fff",
                                fontSize: "1.5em",
                                backgroundColor: "#01ab01",
                                fontWeight: "400",
                                "&:hover": {
                                  opacity: 0.8,
                                  backgroundColor: "#01ab01",
                                },
                              }} // margen inferior para separar del DataGrid
                            >
                              Crear ordenes por lotes (Carga masiva)
                            </Button>
                          ) : (
                            <>
                              <Button
                                variant="outline"
                                startIcon={<FileUploadIcon />}
                                onClick={() =>
                                  cerateOrderDeliveryApiFalabellaOrders(
                                    newOrdersData
                                  )
                                }
                                sx={{
                                  color: "#fff",
                                  fontSize: "1.5em",
                                  backgroundColor: "#f76421",
                                  fontWeight: "400",
                                  "&:hover": {
                                    opacity: 0.8,
                                    backgroundColor: "#f76421",
                                  },
                                }} // margen inferior para separar del DataGrid
                              >
                                Crear ordenes por lotes (Carga masiva Falabella)
                              </Button>
                            </>
                          )}

                          {/* Animación de carga */}
                          {loading && (
                            <Box
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              mt={2}
                              position="relative"
                            >
                              {/* Circular Progress */}
                              <CircularProgress />

                              {/* Imagen centrada dentro del CircularProgress */}
                              <Box
                                sx={{
                                  position: "absolute",
                                  top: "0%",
                                  left: "50%",
                                  transform: "translate(-50%, -50%)",
                                }}
                              >
                                {/* Aquí puedes usar una imagen o un ícono */}
                                <img
                                  src="https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2FLogoBicci01x04.png?alt=media&token=0d36671d-c7df-43e8-97c2-1be128037b00"
                                  alt="Loading icon"
                                  width={100}
                                  height={50}
                                />
                                {/* O usar un ícono de MUI */}
                                {/* <CheckCircleIcon color="primary" fontSize="large" /> */}
                              </Box>

                              {/* Mensaje de texto */}
                              <Typography variant="body1" sx={{ ml: 2 }}>
                                Cargando, por favor espera...
                              </Typography>
                            </Box>
                          )}

                          {/* Mostrar mensaje de error si existe */}
                          {errorMessage && (
                            <Typography
                              variant="body2"
                              color="error"
                              sx={{ mt: 2 }}
                            >
                              {errorMessage}
                            </Typography>
                          )}
                        </>
                      ) : (
                        <></>
                        // <Box sx={{ width: "100%", p: 1, m: 2 }}>
                        //   <h2>Envios creados...</h2>
                        //   <Box>
                        //     <Button
                        //       variant="outline"
                        //       onClick={() => {
                        //         handleReset();
                        //       }}
                        //       sx={{
                        //         color: "#fff",
                        //         fontSize: "1.5em",
                        //         backgroundColor: "#01ab01",
                        //         fontWeight: "400",
                        //         "&:hover": {
                        //           opacity: 0.8,
                        //           backgroundColor: "#01ab01",
                        //         },
                        //         m: 1,
                        //       }} // margen inferior para separar del DataGrid
                        //     >
                        //       Crear nuevos envios
                        //     </Button>
                        //     <Button
                        //       variant="outline"
                        //       onClick={() => {
                        //         window.location.href = "/dashboard/guias/view";
                        //       }}
                        //       sx={{
                        //         color: "#fff",
                        //         fontSize: "1.5em",
                        //         backgroundColor: "#01ab01",
                        //         fontWeight: "400",
                        //         "&:hover": {
                        //           opacity: 0.8,
                        //           backgroundColor: "#01ab01",
                        //         },
                        //         m: 1,
                        //       }} // margen inferior para separar del DataGrid
                        //     >
                        //       Ir a mis envíos
                        //     </Button>
                        //   </Box>
                        // </Box>
                      )}
                    </>
                  )}
                </>
              ) : null}
            </Box>
          </Box>
        </Grid>

        {companiesData && companyData && prefix ? (
          <ReusableFormImportOrdersByDataSDD
            open={openImportDataSDD}
            setOpen={setOpenImportDataSDD}
            handleClose={() => setOpenImportDataSDD(false)}
            companyData={companyData}
            userDataContext={userDataContext}
            prefix={prefix}
            dataOrigin={"DATA"}
            returnDocumentData={returnDocumentData}
          />
        ) : null}

        {companiesData && companyData && prefix ? (
          <ReusableFormImportOrdersByDataSDDFalabella
            open={openImportDataSDDFalabella}
            setOpen={setOpenImportDataSDDFalabella}
            handleClose={() => setOpenImportDataSDDFalabella(false)}
            companyData={companyData}
            userDataContext={userDataContext}
            prefix={prefix}
            dataOrigin={"DATA"}
            returnDocumentDataFalabella={returnDocumentDataFalabella}
          />
        ) : null}
      </Grid>
      {companiesData && companyData && prefix ? (
        <Grid
          item
          sx={{
            width: {
              xs: "100%",
              sm: "100%",
              md: "100%",
              lg: "100%",
            },
            height: "90vh",
            backgroundColor: "#fff",
            p: 3,
          }}
        >
          {companyData.token ? (
            <>
              {readyToLaucher === false ? (
                <Box sx={{ width: "100%", p: 1, m: 2 }}>
                  {" "}
                  <EditableOrderGrid
                    data={newOrdersData}
                    errors={errorOrederData}
                    mode={mode}
                    onSave={handleOnSave}
                  ></EditableOrderGrid>
                </Box>
              ) : (
                <>
                  {!recentCreated ? (
                    <Box sx={{ width: "100%", p: 1, m: 2 }}>
                      <h2>
                        Todo listo para crear {newOrdersData.length} nuevos
                        envíos
                      </h2>
                    </Box>
                  ) : (
                    <Box sx={{ width: "100%", p: 1, m: 2 }}>
                      <h2>Envios creados...</h2>
                      <Box>
                        <Button
                          variant="outline"
                          onClick={() => {
                            handleReset();
                          }}
                          sx={{
                            color: "#fff",
                            fontSize: "1.5em",
                            backgroundColor: "#01ab01",
                            fontWeight: "400",
                            "&:hover": {
                              opacity: 0.8,
                              backgroundColor: "#01ab01",
                            },
                            m: 1,
                          }} // margen inferior para separar del DataGrid
                        >
                          Crear nuevos envios
                        </Button>
                        <Button
                          variant="outline"
                          onClick={() => {
                            window.location.href = "/dashboard/guias/view";
                          }}
                          sx={{
                            color: "#fff",
                            fontSize: "1.5em",
                            backgroundColor: "#01ab01",
                            fontWeight: "400",
                            "&:hover": {
                              opacity: 0.8,
                              backgroundColor: "#01ab01",
                            },
                            m: 1,
                          }} // margen inferior para separar del DataGrid
                        >
                          Ir a mis envíos
                        </Button>
                      </Box>
                    </Box>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              {companyData.name !== "Falabella" ? (
                <Card sx={{ m: 2 }}>
                  <CardContent>
                    No se ha encontrado un token válido para la{" "}
                    <strong>creación de órdenes express</strong>. Puede intentar
                    crear la orden a través de un documento de importación o
                    contactar al soporte para validar el estado de su
                    integración.
                  </CardContent>
                </Card>
              ) : (
                <>
                  {readyToLaucher === false ? (
                    <Box sx={{ width: "100%", p: 1, m: 2 }}>
                      {" "}
                      <EditableOrderGridFalabella
                        data={newOrdersData}
                        errors={errorOrederData}
                        mode={mode}
                        onSave={handleOnSaveFalabella}
                      ></EditableOrderGridFalabella>
                    </Box>
                  ) : null}
                </>
              )}
            </>
          )}
        </Grid>
      ) : (
        <Grid
          item
          sx={{
            width: {
              xs: "100%",
              sm: "100%",
              md: "100%",
              lg: "100%",
            },
            height: "90vh",
            backgroundColor: "#fff",
            p: 3,
          }}
        >
          <Card sx={{ m: 2 }}>
            <CardContent>"Seleccione empresa para continuar"</CardContent>
          </Card>
        </Grid>
      )}
    </div>
  );
};

export default CreateOrderMasive;
