import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Avatar,
  CardHeader,
  Box,
  Alert,
  AlertTitle,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { assingMultipleDriver } from "../../redux/accionesDrivers";

const PopUpAsignament = ({
  ordersForAccions,
  ridersDataByCompany,
  opemPopUpAsignament,
  setOpemPopUpAsignament,
}) => {
  const response = useSelector((store) => store.usuarioDriver.response);

  const [selectedRider, setSelectedRider] = useState(null);
  const [apiResponses, setApiResponses] = useState("");
  const [apiError, setApiError] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    if (response) {
      try {
        if (response.result.status === "success") {
          setApiResponses(response.result.message);
        } else {
          setApiError(`Error en la asignación.`);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }, [response]);

  useEffect(() => {}, [opemPopUpAsignament]);

  // Función para dividir el array en lotes de tamaño 'size'
  const chunkArray = (array, size) => {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size));
    }
    return result;
  };

  const asignarConductor = async () => {
    console.log("asignarConductor", ordersForAccions);
    if (selectedRider) {
      // Dividir ordersForAccions en lotes de 20
      const batches = chunkArray(ordersForAccions, 20);

      try {
        // Procesar cada lote secuencialmente
        for (const batch of batches) {
          var dataAsid = {
            data: {
              driverID: selectedRider.id,
              orderIDs: batch,
            },
          };

          dispatch(assingMultipleDriver(dataAsid));
        }

        // Aquí puedes mostrar las respuestas en el popup actualizando el estado
        // El popup permanecerá abierto para que el usuario pueda ver las respuestas
      } catch (error) {
        console.error("Error al asignar conductor:", error);
        setApiError(error.message || "Error desconocido");
      }
    }
  };

  const handleClose = () => setOpemPopUpAsignament(false);

  const handleRowClick = (params) => {
    setSelectedRider({
      id: params.id,
      name: params.row.name,
      avatar: params.row.avatar,
    });
  };

  const columnsRiders = [
    {
      field: "conductor",
      headerName: "Seleccione un conductor",
      width: 300,
      renderCell: (params) => (
        <CardHeader
          avatar={<Avatar src={params.row.avatar} />}
          title={params.row.name}
          subheader={params.row.bicciNumber}
        />
      ),
    },
  ];

  // console.log(ordersForAccions);
  return (
    <Dialog
      open={opemPopUpAsignament}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Asignar conductor</DialogTitle>
      <DialogContent>
        <Box
          sx={{ width: "100%", height: 20, p: 1, backgroundColor: "#fafafa" }}
        >
          Envíos seleccionados para asignar : {ordersForAccions.length}
        </Box>
        <Box sx={{ width: "100%", height: 350, backgroundColor: "#fafafa" }}>
          {ridersDataByCompany && (
            <DataGrid
              rows={ridersDataByCompany}
              columns={columnsRiders}
              pageSize={5}
              rowsPerPageOptions={[5]}
              onRowClick={handleRowClick}
            />
          )}
        </Box>
        {selectedRider && (
          <CardHeader
            avatar={<Avatar src={selectedRider.avatar} />}
            title={selectedRider.name}
          />
        )}
        {apiResponses.length > 0 && (
          <div>
            <Typography variant="h6" gutterBottom>
              Respuestas de la API:
            </Typography>
            <Alert severity={response ? "success" : "error"} sx={{ mb: 2 }}>
              <AlertTitle>Lote </AlertTitle>
              {response || "Operación realizada."}
              {/* Puedes agregar más detalles si es necesario */}
            </Alert>
          </div>
        )}

        {apiError && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {apiError}
          </Alert>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancelar
        </Button>
        {ordersForAccions.length > 0 ? (
          <Button onClick={asignarConductor} color="primary">
            Asignar conductor
          </Button>
        ) : (
          <Button color="primary">0 envíos seleccionados</Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default PopUpAsignament;
