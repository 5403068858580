import React, { useState } from "react";
import axios from "axios";
import { Card, LinearProgress } from "@mui/material";
import * as XLSX from "xlsx";
import { db } from "../../../../firebase";
import {
  doc,
  updateDoc,
  collection,
  getDoc,
  query,
  where,
  getDocs,
  Timestamp,
} from "firebase/firestore";
import { useDispatch } from "react-redux";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { deleteDoc } from "firebase/firestore";

const ToolDashUpdateDevolutions = (props) => {
  const [mensajeLoadDataEstatusID, setMensajeLoadDataEstatusID] =
    useState(null); // Estado para el mensaje de error
  const dispacth = useDispatch();

  const [dataDevolution, setDataDevolution] = useState(null);

  const handleFileDevolutionDoc = async (e) => {
    console.log("Aqui Archivo devoluciones");

    if (e.target.files.length > 0) {
      props.setAlertMensajes("seleccionado 1 Archivo");
    } else {
      props.setAlertMensajes("Ningún archivo seleccionado.");
    }

    const file = e.target.files[0];
    const data = await file.arrayBuffer();
    const woskbook = XLSX.read(data);

    const worksheet = woskbook.Sheets["DATA"];
    const jsonData = XLSX.utils.sheet_to_json(worksheet);

    //Validar Nombres
    try {
      const lpn = jsonData[0]["LPN"];
      const id = jsonData[0]["SOC"];
      const idDevolution = jsonData[0]["BAJA"];
      const dateDevolution = jsonData[0]["FECHABAJA"];
      const comentDevolution = jsonData[0]["COMENTARIOBAJA"];
      // PaymentProforma // PaymentValor // PaymentType_service // BICCIID
      if (lpn && id && idDevolution && dateDevolution && comentDevolution) {
        props.setAlertMensajes("un archivo seleccionado");
        setDataDevolution(jsonData);
        // props.setDocumentData(jsonData);
        // props.setDataType("billing");
        //props.setRender(!props.remder);
      } else {
        props.setAlertMensajes(
          <div>
            {`Nonmbres clave requeridos revise el documento y ajustelo para
              const lpn = jsonData[0]["LPN"]; 
              const id = jsonData[0]["SOC"];
              const idDevolution = jsonData[0]["BAJA"]; 
              const dateDevolution = jsonData[0]["FECHABAJA"]; 
              const comentDevolution = jsonData[0]["FECHABAJA"];`}
          </div>
        );
      }
    } catch (error) {
      console.log(error);
      props.setAlertMensajes("No se encuentra la hoja con nombre HOJA : DATA");
    }

    if (jsonData && jsonData.length > 0) {
      for (let n = 0; n < jsonData.length; n++) {
        const element = jsonData[n];
        let companyIdOrder = "F" + element.SOC.toString();
        const orderRef = doc(
          db,
          process.env.REACT_APP_COL_ORDERS,
          companyIdOrder
        );
        const docSnapshot = await getDoc(orderRef);
        if (docSnapshot.exists()) {
          console.log("existe en tabla orders express");

          await updateDoc(orderRef, {
            idDevolution: element.idDevolution ? element.idDevolution : "",
            dateDevolution: element.dateDevolution
              ? element.dateDevolution
              : "",
            statusComemmty: element.comentDevolution
              ? element.comentDevolution
              : "",
            status: "Devolucion",
            int_bicciStatus: "Devolucion",
            int_falabella_status: "Devolucion",
            int_falabella_status_descriptor: "Devolucion",
          });
        } else {
          // await setDoc(orderRef, {
          //   idDevolution: element.idDevolution ? element.idDevolution : "",
          //   dateDevolution: element.dateDevolution
          //     ? element.dateDevolution
          //     : "",
          //   comentDevolution: element.comentDevolution
          //     ? element.comentDevolution
          //     : "",
          // });
          console.log(element);
        }
      }
    }
  };

  const handleFileasiceCommets = async (e) => {
    console.log("Aqui Archivo devoluciones");

    if (e.target.files.length > 0) {
      props.setAlertMensajes("Seleccionado 1 Archivo");
    } else {
      props.setAlertMensajes("Ningún archivo seleccionado.");
      return; // Termina la función si no hay archivos
    }

    const file = e.target.files[0];
    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data);

    const worksheet = workbook.Sheets["DATA"];
    const jsonData = XLSX.utils.sheet_to_json(worksheet);

    // Validar Nombres
    try {
      if (jsonData && jsonData.length > 0) {
        for (let n = 0; n < jsonData.length; n++) {
          const element = jsonData[n];
          let companyIdOrder = "F" + element.BICCIID.toString();
          let newComent = element.COMMET; // Asegúrate de que el campo se llame COMMET en tu archivo Excel

          const orderRef = doc(
            db,
            process.env.REACT_APP_COL_USERS_ORDERS,
            companyIdOrder
          );
          const docSnapshot = await getDoc(orderRef);

          if (docSnapshot.exists()) {
            console.log("ACTUALIZANDO COMENTARIO DE ADMIN");

            // Obtén los comentarios actuales o inicializa un array vacío si no existe
            let currentComments = docSnapshot.data().observationAdmin || [];

            // Agrega el nuevo comentario
            currentComments.push(newComent);

            // Actualiza el documento con el array modificado
            await updateDoc(orderRef, { observationAdmin: currentComments });

            console.log("Comentarios actualizados con éxito.");
          } else {
            console.log("NO SE ENCONTRO EL ID:", element.BICCIID);
          }
        }
      }
    } catch (error) {
      console.error("Error al actualizar los comentarios:", error);
    }
  };

  const handleFileCorrection = async (e) => {
    console.log("Aquí Archivo corrección");

    if (e.target.files.length > 0) {
      props.setAlertMensajes("Seleccionado 1 Archivo");
    } else {
      props.setAlertMensajes("Ningún archivo seleccionado.");
      return;
    }

    const file = e.target.files[0];
    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data);

    const worksheet = workbook.Sheets["DATAORRECTION"];
    const jsonData = XLSX.utils.sheet_to_json(worksheet);

    try {
      if (jsonData && jsonData.length > 0) {
        for (let n = 0; n < jsonData.length; n++) {
          const element = jsonData[n];
          let companyIdOrder = element.BICCIID.toString();

          // Crear el Timestamp específico para 26/08/2024 10:00 PM
          // const specificDate = new Date(
          //   "26 de agosto de 2024, 5:54:46 a.m. UTC-4"
          // );
          // const updatedAtTimestamp = Timestamp.fromDate(specificDate);
          const specificDate = new Date(Date.UTC(2024, 7, 26, 9, 54, 46)); // Date.UTC(year, monthIndex (0-11), day, hour, minute, second)
          const updatedAtTimestamp = Timestamp.fromDate(specificDate);
          const orderRef = doc(
            db,
            process.env.REACT_APP_COL_FALABELLA_ORDERS,
            companyIdOrder
          );
          const docSnapshot = await getDoc(orderRef);

          if (docSnapshot.exists()) {
            console.log("ACTUALIZANDO ERROR DE CARGA");

            await updateDoc(orderRef, {
              updatedAt: updatedAtTimestamp, // Aquí se actualiza con la fecha específica
            });

            console.log("Corrección realizada con éxito.");
          } else {
            console.log("NO SE ENCONTRÓ EL ID:", element.BICCIID);
          }
        }
      }
    } catch (error) {
      console.error("Error al actualizar los DATA:", error);
    }
  };

  const handleFileCorrectionF = async (e) => {
    console.log("Aqui Archivo correcion");

    if (e.target.files.length > 0) {
      props.setAlertMensajes("Seleccionado 1 Archivo");
    } else {
      props.setAlertMensajes("Ningún archivo seleccionado.");
      return;
    }

    const file = e.target.files[0];
    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data);

    const worksheet = workbook.Sheets["DATAORRECTION"];
    const jsonData = XLSX.utils.sheet_to_json(worksheet);

    try {
      if (jsonData && jsonData.length > 0) {
        for (let n = 0; n < jsonData.length; n++) {
          const element = jsonData[n];
          let companyIdOrder = element.BICCIID.toString();
          let newBU = element.BU;
          let newDelivery_type = element.TIPODELIVERY;
          const orderRef = doc(
            db,
            process.env.REACT_APP_COL_FALABELLA_ORDERS,
            companyIdOrder
          );
          const docSnapshot = await getDoc(orderRef);

          if (docSnapshot.exists()) {
            console.log("ACTUALIZANDO ERROR DE CARGA");

            await updateDoc(orderRef, {
              bu: newBU,
              delivery_type: newDelivery_type,
            });

            console.log("Correcion realizada con éxito.");
          } else {
            console.log("NO SE ENCONTRO EL ID:", element.BICCIID);
          }
        }
      }
    } catch (error) {
      console.error("Error al actualizar los DATA:", error);
    }
  };

  const handleFileDeleteDocExpress = async (e) => {
    console.log("Aqui Archivo correcion express");

    if (e.target.files.length > 0) {
      props.setAlertMensajes("Seleccionado 1 Archivo");
    } else {
      props.setAlertMensajes("Ningún archivo seleccionado.");
      return;
    }

    const file = e.target.files[0];
    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data);

    const worksheet = workbook.Sheets["DATADELED"];
    const jsonData = XLSX.utils.sheet_to_json(worksheet);

    try {
      if (jsonData && jsonData.length > 0) {
        for (let n = 0; n < jsonData.length; n++) {
          const element = jsonData[n];
          let companyIdOrder = element.BICCIID.toString();
          const orderRef = doc(
            db,
            process.env.REACT_APP_COL_ORDERS,
            companyIdOrder
          );
          const docSnapshot = await getDoc(orderRef);

          if (docSnapshot.exists()) {
            await deleteDoc(orderRef);

            console.log("Correcion deleteDoc con éxito.", element.BICCIID);
          } else {
            console.log("NO SE ENCONTRO EL ID:", element.BICCIID);
          }
        }
      }
    } catch (error) {
      console.error("Error al actualizar los DATA:", error);
    }
  };

  const handleFileDeleteDocFalabella = async (e) => {
    console.log("Aqui Archivo correcion express");

    if (e.target.files.length > 0) {
      props.setAlertMensajes("Seleccionado 1 Archivo");
    } else {
      props.setAlertMensajes("Ningún archivo seleccionado.");
      return;
    }

    const file = e.target.files[0];
    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data);

    const worksheet = workbook.Sheets["DATADELED"];
    const jsonData = XLSX.utils.sheet_to_json(worksheet);

    try {
      if (jsonData && jsonData.length > 0) {
        for (let n = 0; n < jsonData.length; n++) {
          const element = jsonData[n];
          let companyIdOrder = element.BICCIID.toString();
          const orderRef = doc(
            db,
            process.env.REACT_APP_COL_FALABELLA_ORDERS,
            companyIdOrder
          );
          const docSnapshot = await getDoc(orderRef);

          if (docSnapshot.exists()) {
            await deleteDoc(orderRef);

            console.log("Correcion deleteDoc con éxito.", element.BICCIID);
          } else {
            console.log("NO SE ENCONTRO EL ID:", element.BICCIID);
          }
        }
      }
    } catch (error) {
      console.error("Error al actualizar los DATA:", error);
    }
  };

  const handleFileDeleteDocBicci = async (e) => {
    console.log("Aqui Archivo correcion express");

    if (e.target.files.length > 0) {
      props.setAlertMensajes("Seleccionado 1 Archivo");
    } else {
      props.setAlertMensajes("Ningún archivo seleccionado.");
      return;
    }

    const file = e.target.files[0];
    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data);

    const worksheet = workbook.Sheets["DATADELED"];
    const jsonData = XLSX.utils.sheet_to_json(worksheet);

    try {
      if (jsonData && jsonData.length > 0) {
        for (let n = 0; n < jsonData.length; n++) {
          const element = jsonData[n];
          let companyIdOrder = element.BICCIID.toString();
          const orderRef = doc(
            db,
            process.env.REACT_APP_COL_USERS_ORDERS,
            companyIdOrder
          );
          const docSnapshot = await getDoc(orderRef);

          if (docSnapshot.exists()) {
            await deleteDoc(orderRef);

            console.log("Correcion deleteDoc con éxito.", element.BICCIID);
          } else {
            console.log("NO SE ENCONTRO EL ID:", element.BICCIID);
          }
        }
      }
    } catch (error) {
      console.error("Error al actualizar los DATA:", error);
    }
  };

  const handleFilePiking = async (e) => {
    console.log("Aqui Archivo Picking");

    if (e.target.files.length > 0) {
      props.setAlertMensajes("Seleccionado 1 Archivo");
    } else {
      props.setAlertMensajes("Ningún archivo seleccionado.");
      return; // Termina la función si no hay archivos
    }

    const file = e.target.files[0];
    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data);

    const worksheet = workbook.Sheets["PIKINGDOC"];
    const jsonData = XLSX.utils.sheet_to_json(worksheet);

    // Validar Nombres
    try {
      if (jsonData && jsonData.length > 0) {
        for (let n = 0; n < jsonData.length; n++) {
          const element = jsonData[n];
          let companyIdOrder = "F" + element.BICCIID.toString();
          let pickedUp = true;

          const orderRef = doc(
            db,
            process.env.REACT_APP_COL_ORDERS,
            companyIdOrder
          );

          const dataallOrdersfind5 = collection(
            db,
            process.env.REACT_APP_COL_ORDERS
          );

          const docSnapshot = await getDoc(orderRef);

          if (docSnapshot.exists()) {
            console.log("ACTUALIZANDO PIKKING", element.BICCIID);
            await updateDoc(orderRef, { isPickedUp: pickedUp });
            console.log("PIKKING actualizados con éxito.");
          } else {
            const q = query(
              dataallOrdersfind5,
              where("int_lpn", "==", element.BICCIID.toString())
            );
            const querySnapshot5 = await getDocs(q);
            var dataOrdes = querySnapshot5.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            console.log(dataOrdes);
            if (dataOrdes.length > 0 && dataOrdes[0].key) {
              let companyKeyOrder = dataOrdes[0].key;
              const orderRefKey = doc(
                db,
                process.env.REACT_APP_COL_ORDERS,
                companyKeyOrder
              );
              const docSnapshotKey = await getDoc(orderRefKey);
              if (docSnapshotKey.exists()) {
                console.log("SE DOCUMENTO ENCONTRO EL KEY:", dataOrdes[0]);
                await updateDoc(orderRefKey, { isPickedUp: pickedUp });
              } else {
                console.log("NO SE ENCONTRO EL ID:", element.BICCIID);
              }
            } else {
              //900600000000131107
              console.log(
                "DCUMENTO NO CREADO , CONFIRMAR DATA PUEDE SER SOBRANTE:",
                element.BICCIID
              );
            }
          }
        }
      }
    } catch (error) {
      console.error("Error al actualizar PIKKING:", error);
    }
  };

  const handleStatusRecusive = async (e) => {
    console.log("Aqui Archivo devoluciones");

    if (e.target.files.length > 0) {
      props.setAlertMensajes("Seleccionado 1 Archivo");
    } else {
      props.setAlertMensajes("Ningún archivo seleccionado.");
      return; // Termina la función si no hay archivos
    }

    const file = e.target.files[0];
    const dataBuffer = await file.arrayBuffer();
    const workbook = XLSX.read(dataBuffer);

    const worksheet = workbook.Sheets["DATASTATUS"];
    const jsonData = XLSX.utils.sheet_to_json(worksheet);

    // Validar Nombres
    try {
      if (jsonData && jsonData.length > 0) {
        for (const element of jsonData) {
          // Uso de for...of para manejar cada elemento de manera secuencial
          const id = "F" + element.BICCIID.toString();
          const data = {
            data: {
              orderID: id,
              exceptionID: "1031",
            },
          };

          let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: process.env.REACT_APP_BICCI_API_PRO_SEND_EXCEPTION,
            headers: {
              "Content-Type": "application/json",
            },
            data: data,
          };

          try {
            await new Promise((resolve) => setTimeout(resolve, 3000)); // Espera 3 segundos antes de hacer la solicitud
            const response = await axios.request(config);
            console.log(JSON.stringify(response.data));
            console.log("Actualizo los status:", id);
          } catch (error) {
            console.error("Error al procesar el ID:", id, error);
          }
        }
      }
    } catch (error) {
      console.error("Error al actualizar los status:", error);
    }
  };

  const handleFileCosed = async (e) => {
    console.log("Aquí Archivo Cierres");

    if (e.target.files.length > 0) {
      props.setAlertMensajes("Seleccionado 1 Archivo");
    } else {
      props.setAlertMensajes("Ningún archivo seleccionado.");
      return; // Termina la función si no hay archivos
    }

    const file = e.target.files[0];
    try {
      const data = await file.arrayBuffer();
      const workbook = XLSX.read(data);

      if (!workbook.Sheets["DATA"]) {
        throw new Error("La hoja 'DATA' no existe en el archivo.");
      }

      const worksheet = workbook.Sheets["DATA"];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);

      if (jsonData && jsonData.length > 0) {
        for (let n = 0; n < jsonData.length; n++) {
          const element = jsonData[n];

          // Verificaciones antes de acceder a los valores
          let companyIdOrder = element.BICCIID
            ? element.BICCIID.toString()
            : null;
          if (!companyIdOrder) {
            console.warn(`Orden sin ID en la fila ${n + 1}`);
            continue; // Saltar a la siguiente iteración si no hay ID
          }

          let newComent = element.COMMET
            ? element.COMMET
            : "Datos en aplicación externa"; // Asegúrate de que el campo se llame COMMET en tu archivo Excel
          let closedOrder = element.CLOSED ? 8 : 7;
          let userNameReceptor = element.RECEPTOR
            ? element.RECEPTOR
            : "APP externa";
          let userDniReceptor = element.DNIRECEPTOR
            ? element.DNIRECEPTOR
            : "123456789";
          let pics = element.FOTO
            ? element.FOTO
            : "https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2FLogoBicci01x04.png?alt=media&token=0d36671d-c7df-43e8-97c2-1be128037b00";
          let picsTaken = true;

          const orderRef = doc(
            db,
            process.env.REACT_APP_COL_USERS_ORDERS,
            companyIdOrder
          );
          const docSnapshot = await getDoc(orderRef);

          if (docSnapshot.exists()) {
            console.log("ACTUALIZANDO ORDEN DE ADMIN");

            // Obtén los comentarios actuales o inicializa un array vacío si no existe
            let currentComments = docSnapshot.data().observationAdmin || [];

            // Agrega el nuevo comentario
            currentComments.push(newComent);

            // Actualiza el documento con el array modificado
            await updateDoc(orderRef, {
              observationAdmin: currentComments,
              pics: pics,
              userNameReceptor: userNameReceptor,
              userDniReceptor: userDniReceptor,
              closedOrder: closedOrder,
              picsTaken: picsTaken,
            });

            console.log("Comentarios actualizados con éxito.");
          } else {
            console.warn(`NO SE ENCONTRÓ EL ID: ${element.BICCIID}`);
          }
        }
      } else {
        console.warn("No hay datos en la hoja 'DATA'.");
      }
    } catch (error) {
      console.error("Error al procesar el archivo:", error);
      props.setAlertMensajes(
        "Error al procesar el archivo. Verifique los datos."
      );
    }
  };

  return (
    <Card
      className="typeMiniformsBarsAccionsItemsMinimal"
      sx={{ borderRadius: "3px" }}
    >
      <div className="m-2">
        <div className="labelupload">
          <label htmlFor="file-uploadDevolution">
            Actualizar listado de devoluciones
          </label>
          <input
            id="file-uploadDevolution"
            name="file-uploadDevolution"
            type="file"
            onChange={(e) => handleFileDevolutionDoc(e)}
          />
          <label htmlFor="file-uploadDevolution" className="choose-file">
            Devolucion <UploadFileIcon></UploadFileIcon>
          </label>
          <div id="file-upload-message">Ningún archivo seleccionado.</div>
        </div>
        <div className="labelupload">
          <label htmlFor="file-uploadComment">
            Actualizar listado de Commentarios
          </label>
          <input
            id="file-uploadComment"
            name="file-uploadComment"
            type="file"
            onChange={(e) => handleFileasiceCommets(e)}
          />
          <label htmlFor="file-uploadComment" className="choose-file">
            Comentarios <UploadFileIcon></UploadFileIcon>
          </label>
          <div id="file-upload-message">
            Ningún archivo seleccionado para comentarios.
          </div>
        </div>
        <div className="labelupload">
          <label htmlFor="file-uploadState">Actualizar Stados recursivos</label>
          <input
            id="file-uploadState"
            name="file-uploadState"
            type="file"
            onChange={(e) => handleStatusRecusive(e)}
          />
          <label htmlFor="file-uploadState" className="choose-file">
            Estados <UploadFileIcon></UploadFileIcon>
          </label>
          <div id="file-upload-message">
            Ningún archivo seleccionado para estatus.
          </div>
        </div>
        <div className="labelupload">
          <label htmlFor="file-uploadClose">Actualizar Stados recursivos</label>
          <input
            id="file-uploadClose"
            name="file-uploadClose"
            type="file"
            onChange={(e) => handleFileCosed(e)}
          />
          <label htmlFor="file-uploadClose" className="choose-file">
            CIERREs RECIBELO <UploadFileIcon></UploadFileIcon>
          </label>
          <div id="file-upload-message">
            Ningún archivo seleccionado para estatus.
          </div>
        </div>

        {/**
        <div className="labelupload">
          <label htmlFor="file-delete-Express">
            Correction de Ordenes Tabla EXPRESS
          </label>
          <input
            id="file-delete-Express"
            name="file-delete-Express"
            type="file"
            onChange={(e) => handleFileDeleteDocExpress(e)}
          />
          <label htmlFor="file-delete-Express" className="choose-file">
            CORRECCION EXPRESS <UploadFileIcon></UploadFileIcon>
          </label>
          <div id="file-upload-message">
            Ningún archivo seleccionado para Correciones.
          </div>
        </div>
 */}
        {/* <div className="labelupload">
          <label htmlFor="file-uploadCrrection">Correction de Ordenes</label>
          <input
            id="file-uploadCrrection"
            name="file-uploadCrrection"
            type="file"
            onChange={(e) => handleFileDeleteDocFalabella(e)}
          />
          <label htmlFor="file-uploadCrrection" className="choose-file">
            CORRECCION DELETED <UploadFileIcon></UploadFileIcon>
          </label>
          <div id="file-upload-message">
            Ningún archivo seleccionado para Correciones.
          </div>
        </div> */}
        {/*
        <div className="labelupload">
          <label htmlFor="file-uploadCrrectionF">
            Correction de Ordenes Tabla FALABELLA
          </label>
          <input
            id="file-uploadCrrectionF"
            name="file-uploadCrrectionF"
            type="file"
            onChange={(e) => handleFileCorrectionF(e)}
          />
          <label htmlFor="file-uploadCrrectionF" className="choose-file">
            CORRECCION FALABELLA <UploadFileIcon></UploadFileIcon>
          </label>
          <div id="file-upload-message">
            Ningún archivo seleccionado para Correciones.
          </div>
        </div>

        <div className="labelupload">
          <label htmlFor="file-uploadState">Actualizar Picking</label>
          <input
            id="file-picking"
            name="file-picking"
            type="file"
            onChange={(e) => handleFilePiking(e)}
          />
          <label htmlFor="file-picking" className="choose-file">
            Picking <UploadFileIcon></UploadFileIcon>
          </label>
          <div id="file-upload-message">
            Ningún archivo seleccionado para piking.
          </div>
        </div>
        {/* <div>
          {dataPayment ? (
            <>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={() => loadOrdesDataSdd(dataPayment)}
                sx={{ color: "#591e8f" }}
              >
                <PlayCircleIcon
                  className="btn-add-guide-small"
                  sx={{ color: dataPayment ? "#F8F8F8" : "#591e8f" }}
                />
              </IconButton>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={() => pause()}
                sx={{ color: "#591e8f" }}
              >
                <PauseIcon
                  className="btn-add-guide-small"
                  sx={{ color: dataPayment ? "#F8F8F8" : "#591e8f" }}
                />
              </IconButton>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={() => cancel()}
                sx={{ color: "#591e8f" }}
              >
                <CancelIcon
                  className="btn-add-guide-small-cancel "
                  sx={{ color: dataPayment ? "#F8F8F8" : "#591e8f" }}
                />
              </IconButton>
            </>
          ) : null}
        </div> */}
        <div>
          <span className="float-letf">{mensajeLoadDataEstatusID}</span>
        </div>
        <div>
          {props.LoadDataEstatus ? <LinearProgress></LinearProgress> : null}
        </div>
      </div>
    </Card>
  );
};

export default ToolDashUpdateDevolutions;
