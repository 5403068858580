import React, { createRef, useState } from "react";
import { db } from "../../../firebase";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  doc,
  setDoc,
  deleteDoc,
  updateDoc,
} from "firebase/firestore";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { Card, Divider, CardHeader, Box } from "@mui/material";
import { Container } from "semantic-ui-react";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import FadeInOut from "../../../components/animations/FadeInOut";
import { Grid } from "semantic-ui-react";
import DeleteIcon from "@mui/icons-material/Delete";
import BlockIcon from "@mui/icons-material/Block";
import Chip from "@mui/material/Chip";
import DashIconsResumen from "../../../components/Dashboard/DashIconsResumen/DashIconsResumen";
import { DataGrid } from "@mui/x-data-grid";
import useGetDataRiderByCompany from "../../../hooks/getDataRiderByCompany";
import useObtenerDatosdeCliente from "../../../hooks/obtenerDatosdeCliente";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CellTowerIcon from "@mui/icons-material/CellTower";
import moment from "moment";
import { LinearProgress } from "@mui/material";
import Popup from "../../../components/Popup";
import DriversDetails from "../DriversDetails/DriversDetails";
import UserListViewDrivers from "../../../components/UserListViewDrivers/UserListViewDrivers";

const Drivers = (props) => {
  const userUid = useSelector((store) => store.usuario);

  //var companyId=null
  //const {userBalance  } = useObtenerBalance(userCompanyID)
  const [companyId, setcompanyId] = useState("");
  const [emailRider, setEmailRider] = useState("");
  const [isvalid, setIsValid] = useState("");
  const [userRider, setUserRiderr] = React.useState("");
  const [showEdit, setShowEdit] = useState(false);
  const [error, setError] = useState(null);
  const [ridersRows, setRidersRows] = React.useState("");
  const [ridersRowsInactive, setRidersRowsInactive] = React.useState("");

  const [showPopup, setshowPopup] = useState(false);

  const [typePop, setTypePop] = useState(null);

  const [cantListItems, setcantListItems] = React.useState({
    Activos: 0,
    Conectados: 0,
    Invitados: 0,
    Inactivos: 0,
  });

  const [objRider, setobjRider] = React.useState({
    title: "showPopupWiewRiders",
    texto: "Lorem ipsum dolor.",
  });

  const [toggle, setToggle] = useState(false);

  const { userdata, data } = useObtenerDatosdeCliente(userUid.user.uid);

  const { ridersDataByCompany, listRidersDataByCompany } =
    useGetDataRiderByCompany(companyId);

  const listItems = [
    { id: "Activos" },
    { id: "Conectados" },
    { id: "Invitados" },
    { id: "Inactivos" },
  ];

  useEffect(() => {}, [toggle]);

  useEffect(() => {
    //console.log(companyId)
  }, [companyId]);

  useEffect(() => {
    //console.log(userdata)
    if (userdata) {
      setcompanyId(userdata.userCompanyID);
    }
  }, [data, userdata]);

  useEffect(() => {
    getBicciDriversData();
  }, [ridersDataByCompany && listRidersDataByCompany]);

  const togglePopup = () => {
    setshowPopup(!showPopup);
  };

  const toggleShowEditNew = () => {
    setShowEdit(!showEdit);
    //console.log('Abrir inviatcion')
  };

  const invitarUsuariosRider = async (e) => {
    if (!emailRider.trim()) {
      setError("Ingrese un email valido");
      setIsValid(false);
      return;
    }

    if (isvalid === true) {
      let internalValid = {
        validacion: "",
        get validacionGet() {
          return this.validacion;
        },
        set validacionSet(value) {
          this.validacion = value;
        },
      };
      let rider = {
        data: {
          invitedAt: "",
          email: emailRider,
          createdAt: Date.now(),
          createdateAdd: moment(Date.now()).format("DD MMMM YYYY, h:mm:ss a"),
          id: "",
          bicciNumber: "",
          isblocked: false,
          isDeleted: false,
          isInvited: true,
          wasAccepted: false,
        },
        get dataRider() {
          return this.data;
        },
        get email() {
          return this.data.email;
        },
        get id() {
          return this.data.id;
        },
        set invitedAt(invitedAt) {
          this.data.invitedAt = invitedAt;
        },
        set email(email) {
          this.data.email = email;
        },
        set createdAt(createdAt) {
          this.data.createdAt = createdAt;
        },
        set id(id) {
          this.data.id = id;
        },
        set bicciNumber(bicciNumber) {
          this.data.bicciNumber = bicciNumber;
        },
      };

      const collectionRef = collection(db, process.env.REACT_APP_COL_COMPANIES);
      const docRef = doc(collectionRef, userdata.userCompanyID);
      const collectionRidersCompany = collection(docRef, "companydrivers");

      try {
        const driversCollectionRef = collection(
          db,
          `${process.env.REACT_APP_COL_DRIVERS}`
        );

        const q = query(driversCollectionRef, where("email", "==", emailRider));
        const driverCompanyQuerySnapshot = await getDocs(q);
        const driverCompanytemp = [];
        driverCompanyQuerySnapshot.forEach((doc) => {
          // Agregamos los datos del documento a un array.
          driverCompanytemp.push({ id: doc.id, ...doc.data() });
        });

        if (driverCompanytemp.length > 0) {
          setError("Ya se envío una invitación a este usuario ");
          internalValid.validacionSet = false;
          setShowEdit(true);
          throw e;
        } else {
          internalValid.validacionSet = true;
          const riderDocRef = doc(collectionRidersCompany, rider.email);

          await setDoc(riderDocRef, rider.dataRider);
        }
      } catch (error) {
        console.log(error);
      }

      if (internalValid.validacion === true) {
        try {
          const driversCollectionRef = collection(
            db,
            `${process.env.REACT_APP_COL_USERS_DRIVERS}`
          );
          const q = query(
            driversCollectionRef,
            where("email", "==", emailRider.trim())
          );
          const driverCompanyQuerySnapshot = await getDocs(q);
          const response = driverCompanyQuerySnapshot.docs[0].data();

          if (response) {
            rider.email = response.email;
            rider.id = response.id;
            rider.invitedAt = moment(Date.now()).format(
              "DD MMMM YYYY, h:mm:ss a"
            );
            rider.bicciNumber = response.bicciNumber;
            const riderDocRef = doc(db, docRef.path, rider.email);
            await updateDoc(riderDocRef, rider.dataRider);
            setShowEdit(false);
            setEmailRider("");
          } else {
            setShowEdit(false);
            setEmailRider("");
          }
          //var driverCompanyBxResult = driverCompanyBX.map(doc => ({id: doc.id, ...doc.data()}))
          //console.log(driverCompanyBxResult)
        } catch (error) {
          //console.log(error)
        }

        var array = ridersRowsInactive.map((item) => item);
        array.push({
          id: Math.floor(Math.random() * 10000 + 1).toString(),
          name: "---",
          email: rider.email,
          bicciNumber: "No Asignado",
          estado: "Invitación enviada",
        });
        setRidersRowsInactive(array);
        //console.log(ridersRowsInactive)
      }
    }
  };

  const handlechage = (e) => {
    const validateEmail = (email) => {
      return email.match(
        /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
      );
    };
    const validate = (email) => {
      if (validateEmail(email)) {
        setError(null);
        return true;
      } else {
        setError("Ingrese un email valido");
        return false;
      }
    };
    let result = validate(e.target.value);
    if (e.target.value === "") {
      setError(null);
    }
    setIsValid(result);
    setEmailRider(e.target.value);
  };

  const columnsRiders = [
    {
      field: "name",
      headerName: "Nombre",
      sortable: false,
      width: 150,
    },
    {
      field: "email",
      headerName: "Rider Email",
      width: 200,
      editable: true,
    },
    {
      field: "bicciNumber",
      headerName: "Bicci Number",
      sortable: false,
      width: 100,
    },
    {
      field: "estado",
      headerName: "Estado",
      sortable: false,
      width: 250,
      renderCell: (ridersRows) => {
        //console.log(ridersRows.row.active)
        return (
          <>
            <Chip
              label={
                ridersRows.row.active === true ? "Activo   " : "No Activado"
              }
              icon={
                <AccountCircleIcon
                  style={{
                    color: "#fff",
                  }}
                />
              }
              style={{
                color: "#010101",
                backgroundColor:
                  ridersRows.row.active === true ? "#1ef708" : "No Activado",
              }}
            />
            <Chip
              label={
                ridersRows.row.online === true ? "OnLine   " : "Desconectado"
              }
              icon={
                <CellTowerIcon
                  style={{
                    color: "#fff",
                  }}
                />
              }
              variant="outlined"
              style={{
                color: "#010101",
                backgroundColor:
                  ridersRows.row.online === true ? "#1ef708" : "#f7e908",
              }}
            />
          </>
        );
      },
    },
    {
      field: "actions",
      headerName: "Acciones",
      width: 300,
      renderCell: (ridersRows) => {
        //console.log(ridersRows)
        return (
          <>
            <Chip
              label="Ver detalles"
              onClick={showPopupWiewRiders}
              id={ridersRows.row.id}
              value={ridersRows.row.id}
              variant="outlined"
              style={{
                color: "#fff",
                backgroundColor: "#591e8f",
              }}
            />
            <Chip
              label="Bloquear"
              onClick={handleBloqPropsRidersRows}
              id={ridersRows.row.id}
              value={ridersRows.row.id}
              icon={<BlockIcon />}
              variant="outlined"
              style={{
                color: "#010101",
                backgroundColor: "#f7e908",
              }}
            />
            <Chip
              label="Eliminar"
              onClick={handleDeletedPropsRidersRows}
              id={ridersRows.row.id}
              value={ridersRows.row.id}
              deleteIcon={<DeleteIcon />}
              variant="outlined"
              style={{
                color: "#fff",
                backgroundColor: "#d5182b",
              }}
            />
          </>
        );
      },
    },
  ];

  const columnsRidersInactive = [
    {
      field: "email",
      headerName: "Rider Email",
      width: 200,
      editable: false,
    },
    {
      field: "name",
      headerName: "Nombre",
      sortable: false,
      width: 150,
    },
    {
      field: "bicciNumber",
      headerName: "Bicci Number",
      sortable: false,
      width: 100,
    },
    {
      field: "estado",
      headerName: "estado",
      sortable: false,
      width: 200,
    },
    {
      field: "id",
      headerName: "id",
      sortable: true,
      width: 200,
    },
    {
      field: "actions",
      headerName: "Acciones",
      width: 200,
      renderCell: (ridersRowsInactive) => {
        //console.log(ridersRows)
        return (
          <>
            <button
              className="btn-bicci-small btn-globalhover-calipso"
              variant="contained"
              color="primary"
              size="small"
              value={ridersRowsInactive.row.id}
              onClick={handleWiewInactive}
              style={{
                marginLeft: 2,
                outline: 0,
                padding: "0.5rem 0.5rem",
                fontWeight: 500,
                color: "#fff",
                backgroundColor: "#591e8f",
                borderRadius: "5px",
                cursor: "pointer",
                minWidth: "83px",
              }}
              // onClick={handleGoPropsRidersRows}
            >
              ver detalles
            </button>
            <Chip
              label="Eliminar"
              onClick={handleDelete}
              id={ridersRowsInactive.row.id}
              value={ridersRowsInactive.row.id}
              onDelete={handleDelete}
              deleteIcon={<DeleteIcon />}
              variant="outlined"
              style={{
                color: "#fff",
                backgroundColor: "#d5182b",
              }}
            />
          </>
        );
      },
    },
  ];

  const showPopupWiewRiders = (e) => {
    var search = ridersRows.find((item) => item.id === e.currentTarget.id);
    var searchData = ridersDataByCompany.find(
      (item) => item.email === search.email
    );

    setTypePop("rider");
    setobjRider({
      title: search.name,
      texto: "informacion del usuario.",
      data: searchData,
    });
    togglePopup();
  };

  const handleBloqPropsRidersRows = async (e) => {
    //console.log('BLOQ ACTIVE ' + e.currentTarget.id )
    var search = ridersRows.find((item) => item.id === e.currentTarget.id);
    var searchData = ridersDataByCompany.find(
      (item) => item.email === search.email
    );

    const companyDriversCollectionRef = collection(
      doc(db, `${process.env.REACT_APP_COL_COMPANIES}`, companyId),
      "companydrivers"
    );
    const driverDocRef = doc(companyDriversCollectionRef, searchData.email);

    await updateDoc(driverDocRef, {
      isblocked: true,
    });
    setToggle(!toggle);
  };

  const handleDeletedPropsRidersRows = async (e) => {
    //console.log('DELETE ACTIVE' + e.currentTarget.id )
    //console.log(ridersRows)
    var search = ridersRows.find((item) => item.id === e.currentTarget.id);
    var searchDataRest = ridersRows.filter((item) => item.id !== search.id);
    //console.log(searchDataRest)
    // var arr = []
    // arr.push(searchDataRest)
    //console.log(arr)
    //let ListItems =  Object.values(searchDataRest);
    setRidersRows(searchDataRest);
    //  setcantListItems({...cantListItems, Invitados:searchDataRest.length})
    if (search.active === true) {
      setcantListItems({
        ...cantListItems,
        Activos: cantListItems.Activos - 1,
      });
    }
    if (search.active === false) {
      setcantListItems({
        ...cantListItems,
        Inactivos: cantListItems.Inactivos - 1,
      });
    }
    if (search.online === true) {
      setcantListItems({
        ...cantListItems,
        Conectados: cantListItems.Conectados - 1,
      });
    }

    const companyDriversCollectionRef = collection(
      doc(db, `${process.env.REACT_APP_COL_COMPANIES}`, companyId),
      "companydrivers"
    );
    const driverDocRef = doc(companyDriversCollectionRef, search.email);
    await deleteDoc(driverDocRef);
    setToggle(!toggle);
  };

  const handleWiewInactive = (e) => {
    var search = ridersRowsInactive.find(
      (item) => item.id === e.currentTarget.value
    );
    const vistaitem = ridersRowsInactive.filter(
      (item2) => item2.id === e.currentTarget.value
    );

    //console.log(search)
    setTypePop("riderInactive");
    setobjRider({
      title: search.name,
      texto: "informacion del usuario.",
      data: search,
    });
    togglePopup();
  };

  const handleDelete = async (e) => {
    //console.log('DELETE ' + e.currentTarget.id )
    //console.log(ridersRowsInactive)
    var search = ridersRowsInactive.find(
      (item) => item.id === e.currentTarget.id
    );
    var searchRest = ridersRowsInactive.filter(
      (item) => item.id !== e.currentTarget.id
    );
    //console.log(search)
    //console.log(searchRest)
    // var arr2 = []
    // arr2.push(searchRest)
    //console.log(arr2)
    setRidersRowsInactive(searchRest);
    setcantListItems({ ...cantListItems, Invitados: searchRest.length });

    const companyDriversCollectionRef = collection(
      doc(db, `${process.env.REACT_APP_COL_COMPANIES}`, companyId),
      "companydrivers"
    );
    const driverDocRef = doc(companyDriversCollectionRef, search.email);
    await deleteDoc(driverDocRef);
    setToggle(!toggle);
  };

  const getBicciDriversData = () => {
    //console.log(ridersDataByCompany) // lista de compañia 2
    //console.log(listRidersDataByCompany ) // lista de coicidencias en Bicci 12
    if (ridersDataByCompany && listRidersDataByCompany) {
      //console.log('RENDER')
      let listActive = [];
      let listInvited = [];
      let listOnline = [];
      let listInactive = [];
      let ridersInactive = [];

      for (let i = 0; i < listRidersDataByCompany.length; i++) {
        for (let n = 0; n < ridersDataByCompany.length; n++) {
          if (
            ridersDataByCompany[n].email === listRidersDataByCompany[i].email
          ) {
            if (!listInactive.includes(listRidersDataByCompany[i].email)) {
              if (!listActive.includes(listRidersDataByCompany[i].email)) {
                if (ridersDataByCompany[n].active === true) {
                  listActive.push(listRidersDataByCompany[i].email);
                }
              }
            }

            if (ridersDataByCompany[n].online === true) {
              //console.log(ridersDataByCompany[n].online)
              listOnline.push(listRidersDataByCompany[i].email);
            }

            if (
              ridersDataByCompany[n].active === false ||
              listRidersDataByCompany[i].isDeleted === true ||
              listRidersDataByCompany[i].isblocked === true
            ) {
              //console.log(ridersDataByCompany[n].online)
              listInactive.push(listRidersDataByCompany[i].email);
              //ridersInactive.push ( { id: Math.floor((Math.random() * 10000) + 1) , name: '---',  email: listRidersDataByCompany[i].email,  bicciNumber:'No Asignado', estado:'No activado'} );
            }
          }
        }
      }

      for (let i = 0; i < listRidersDataByCompany.length; i++) {
        for (let n = 0; n < ridersDataByCompany.length; n++) {
          if (
            ridersDataByCompany[n].email !== listRidersDataByCompany[i].email
          ) {
            if (!listInvited.includes(listRidersDataByCompany[i].email)) {
              if (!listActive.includes(listRidersDataByCompany[i].email)) {
                if (!listInactive.includes(listRidersDataByCompany[i].email)) {
                  listInvited.push(listRidersDataByCompany[i].email);
                  ridersInactive.push({
                    id: Math.floor(Math.random() * 10000 + 1).toString(),
                    name: "---",
                    email: listRidersDataByCompany[i].email,
                    bicciNumber: "No Asignado",
                    estado: "Invitación enviada",
                  });
                }
              }
            }
          }
        }
      }

      //console.log(listActive)
      //console.log(listOnline)
      //console.log(listInvited)
      //console.log(listInactive)
      setRidersRowsInactive(ridersInactive);

      //setRidersItemsInactive(listInactive)
      setcantListItems({
        Activos: listActive.length,
        Conectados: listOnline.length,
        Invitados: listInvited.length,
        Inactivos: listInactive.length,
      });
    }

    if (ridersDataByCompany) {
      var ridersRows = [];
      for (let i = 0; i < ridersDataByCompany.length; i++) {
        ridersRows.push({
          id: ridersDataByCompany[i].id,
          name: ridersDataByCompany[i].name,
          email: ridersDataByCompany[i].email,
          bicciNumber: ridersDataByCompany[i].bicciNumber,
          active: ridersDataByCompany[i].active,
          online: ridersDataByCompany[i].online,
        });
      }
      //console.log(ridersRows)
      setRidersRows(ridersRows); // cargar con data de company
    } else {
    }
  };

  return (
    <>
      <Grid celled="internally" id="expresscuentaContent">
        <h2 className="h2-withe">Mis Riders</h2>

        <Grid.Row>
          <Grid.Column
            computer={16}
            mobile={16}
            tablet={16}
            className="dash-container-colmun"
          >
            <Card className="typeMiniforms">
              <Box
                sx={{
                  width: "100%",
                  height: "auto",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Card sx={{ maxWidth: "350px", left: "0px" }}>
                  <CardHeader
                    subheader="Si el colaborador no está registrado, le enviaremos una invitación activar su cuenta. Es necesario completar los datos del conductor para su activación"
                    title="Invitar nuevos usuarios"
                  />
                  {userdata && userdata.userCompanyID ? (
                    <>
                      {showEdit === true ? (
                        <div>
                          <Button
                            sx={{
                              display: "flex",
                              justifyContent: "flex-center",
                              outline: "none",
                              m: 1,
                            }}
                            color="primary"
                            variant="contained"
                            onClick={toggleShowEditNew}
                          >
                            <HighlightOffIcon className="dashIcon-red" />
                            Cancelar
                          </Button>
                        </div>
                      ) : (
                        <div>
                          <Button
                            sx={{
                              display: "flex",
                              justifyContent: "flex-center",
                              outline: "none",
                              m: 1,
                            }}
                            color="primary"
                            variant="contained"
                            onClick={toggleShowEditNew}
                          >
                            <AddCircleIcon className="dashIcon-green" />
                            Agregar conductor
                          </Button>
                        </div>
                      )}
                    </>
                  ) : (
                    <> Debe registrar su empresa para agregar usuarios</>
                  )}

                  <Divider />
                  <FadeInOut show={showEdit} duration={500}>
                    <Container className="formcontent" id="micuentafromIni">
                      <hr></hr>
                      <Form onSubmit={invitarUsuariosRider}>
                        <InputGroup className="mb-3">
                          <Form.Control
                            className={
                              isvalid === false
                                ? "email-novalid"
                                : "email-isvalid"
                            }
                            placeholder="email"
                            aria-label="email"
                            aria-describedby="basic-addon2"
                            type="email"
                            onChange={handlechage}
                            value={emailRider}
                          />
                          <Button
                            variant=" btn-primary"
                            id="button-addon2"
                            onClick={invitarUsuariosRider}
                          >
                            enviar
                          </Button>
                        </InputGroup>
                        {error ? (
                          <span className="alert alert-warning m-1">
                            {error}
                          </span>
                        ) : null}
                      </Form>
                    </Container>
                  </FadeInOut>

                  <Divider />
                </Card>
              </Box>
              <CardHeader
                subheader="Usuarios conductores vinculados con tu empresa"
                title="Conductores"
              />

              <Divider />
              <Container className="formcontent" id="micuentafromIni">
                <hr></hr>

                <div>
                  {/* 
                  <Box
                    sx={{
                      height: "350px",
                      width: "100%",
                      backgroundColor: "#fafafa",
                    }}
                  >
                    {ridersRows ? (
                      <DataGrid
                        initialState={{
                          sorting: {
                            sortModel: [{ field: "bicciNumber", sort: "desc" }],
                          },
                        }}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                        rows={ridersRows}
                        columns={columnsRiders}
                        pageSize={5}
                        rowsPerPageOptions={[10]}
                        disableSelectionOnClick
                        //onClick ={checkboxSelection}
                      />
                    ) : (
                      <>
                        <LinearProgress />
                      </>
                    )}
                  </Box>
                  <Box
                    sx={{
                      height: "350px",
                      width: "100%",
                      backgroundColor: "#fafafa",
                    }}
                  >
                    {ridersRowsInactive ? (
                      <DataGrid
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                        rows={ridersRowsInactive}
                        columns={columnsRidersInactive}
                        pageSize={5}
                        rowsPerPageOptions={[10]}
                        disableSelectionOnClick
                        //onClick ={checkboxSelection}
                      />
                    ) : (
                      <>
                        <LinearProgress />
                      </>
                    )}
                  </Box> */}
                  {ridersDataByCompany ? (
                    <UserListViewDrivers
                      ridersDataByCompany={ridersDataByCompany}
                    ></UserListViewDrivers>
                  ) : (
                    <LinearProgress></LinearProgress>
                  )}
                </div>
              </Container>

              <Divider />
            </Card>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {showPopup ? (
        <Popup
          contenido={objRider}
          togglePopup={togglePopup}
          type={typePop}
        ></Popup>
      ) : null}
    </>
  );
};

export default Drivers;
