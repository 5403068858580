import React, { useEffect, useState } from "react";
import { db } from "../../firebase";
import {
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { Form, Input, Grid, Container } from "semantic-ui-react";
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Box,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
} from "@mui/material";
import { format } from "date-fns";
import TabSecondary from "./TabSecondary";
import useGlobalConfig from "../../hooks/globalConfig";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import MapDeliveryPosition from "./MapDeliveryPosition";
import { TableNoData } from "../Table";
import PrintIcon from "@mui/icons-material/Print";
import {
  translateStatesBicciFilter,
  translateStatesfalabbela,
  translateStatesfalabbelaSelector,
} from "../../utils/objects/translateStates";
import {
  stlyleClassStates,
  styleState,
} from "../../utils/objects/stylesStates";
import ReusableFormExceptions from "../ReusableFormExceptions/ReusableFormExceptions";
import HandlePrintBiccShipping from "../../utils/HandlePrintBiccShipping/HandlePrintBiccShipping";
import { useOrders } from "../../hooks/useOrdersProviders/OrdersContext";
import Moment from "moment";
import { getDocumentAndHistory } from "../../utils/UtilsOrders/getDocumentAndHistory";
import useGetDataRiderByCompany from "../../hooks/getDataRiderByCompany";

var history = [];
const companyId = "77528497-8";
const Dispatchdetails = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  function handleClick() {
    navigate(-1);
  }

  const [orderDetails, setOrderDetails] = useState(null);
  const [orderDetailsCliente, setOrderDetailsClient] = useState(null);
  const [dispachtHistory, setDispachtHistory] = useState([]);
  const [dataOrdesBicciExpress, setDataOrdesBicciExpress] = useState([]);
  const [orderId, setOrderId] = useState("");
  const [userAdminComent, setUserAdminComent] = useState([]);
  const [newComent, setNewComent] = useState("");
  const [openEdit, setOpenEdit] = useState(false);
  const [riderDestails, setRiderDestails] = useState({});

  const [compyOrder, setCompyOrder] = useState({});

  const [loadAwait, setLoatAwait] = useState(null);
  const [findOrder, setFindOrder] = useState(null);

  const [prefixes, setPrefixes] = useState([]);

  const collectionRef = collection(
    db,
    `${process.env.REACT_APP_COL_USERS_ORDERS}`
  );

  let initDateInitial = Moment(Date.now()).format("YYYY-MM-DD");
  let endDateInitial = Moment(Date.now()).format("YYYY-MM-DD");

  const { companiesData } = useOrders({
    initDateInitial,
    endDateInitial,
  });

  const { ridersDataByCompany, listRidersDataByCompany } =
    useGetDataRiderByCompany(companyId);

  useEffect(() => {
    if (companiesData) {
      // Extraemos solo los prefijos en un array separado
      const prefixes = companiesData
        .filter((item) => item.prefix) // Filtrar empresas con prefijo
        .map((item) => item.prefix); // Extraer solo el prefijo
      setPrefixes(prefixes); // Guardar los prefijos

      // Extraemos los objetos completos de las empresas que tienen prefijo
    }
  }, [companiesData]);

  useEffect(() => {
    if (id) {
      setOrderId(id.trim());
    }
  }, [id]);

  useEffect(() => {
    if (orderId && prefixes.length > 0) {
      findOrdesrDispacht(orderId, prefixes);
    }
  }, [orderId && prefixes]);

  useEffect(() => {
    const finRiderDestails = async (id) => {
      try {
        const collectionRider = collection(
          db,
          process.env.REACT_APP_COL_USERS_DRIVERS
        );
        const docRef = doc(collectionRider, id);
        const riderRef = await getDoc(docRef);

        const rider = riderRef.data();
        setRiderDestails(rider);
      } catch (error) {
        //setRiderDestails({ error: "no found" });
      }
    };

    if (orderDetails) {
      finRiderDestails(orderDetails.driverID);
    }
  }, [orderDetails]);

  useEffect(() => {}, [findOrder]);

  const findOrdesrDispacht = async (id, prefixes) => {
    setLoatAwait(true);

    const collections = [
      process.env.REACT_APP_COL_USERS_ORDERS,
      //process.env.REACT_APP_COL_FALABELLA_ORDERS,
      // Agregar otras colecciones si es necesario
    ];

    const { foundDocumentData, documentHistory, prefix } =
      await getDocumentAndHistory(id, prefixes, collections);

    const dataCompany = companiesData.filter((item) => item.prefix === prefix);
    setCompyOrder(dataCompany[0]);

    // console.log(foundDocumentData);
    // console.log(documentHistory);
    // console.log(prefix);

    if (foundDocumentData && foundDocumentData.id) {
      setLoatAwait(false);
      setFindOrder(true);
      setOrderDetails(foundDocumentData);
      setOrderDetailsClient(foundDocumentData);
      setDispachtHistory(documentHistory);
    } else {
      // Manejar el caso en que no se encuentra el documento

      setOrderDetails(null);
      setOrderDetailsClient([]);
      setDispachtHistory([]);
      setLoatAwait(false);
      setFindOrder(false);

      // console.log(
      //   "No se encontró ningún documento con los criterios proporcionados."
      // );
    }

    //  setProgressValue(100);
  };

  const saveComentAdmin = async () => {
    let mergeComents = [];
    if (newComent && newComent.trim()) {
      mergeComents.push(...userAdminComent);
      mergeComents.push(newComent);

      const orderRef3 = doc(collectionRef, `${orderId}`);

      await updateDoc(orderRef3, { observationAdmin: mergeComents });
      orderDetails.observationAdmin = mergeComents;
      setUserAdminComent(mergeComents);
      setNewComent("");
    } else {
    }
  };

  function delay(seconds) {
    return new Promise((resolve) => {
      setTimeout(resolve, seconds * 1000);
    });
  }

  const onSave = async () => {
    await delay(2);
    findOrdesrDispacht(orderId, prefixes);
    setOpenEdit(false);
  };

  let latDriver = null;

  return (
    <>
      {orderDetails ? (
        <>
          {" "}
          <div id="containerDetails">
            <div>
              <h2 className="h2-withe">Detalles de despacho</h2>
            </div>
            <Grid>
              <Grid.Row>
                <Grid.Column computer={10} mobile={16} tablet={16}>
                  <Card className="card-ultimasOrdenes typeMiniforms">
                    <CardHeader
                      title={
                        <div>
                          <h1>{orderDetails.id}</h1>
                          {orderDetailsCliente ? (
                            <span
                              className={
                                styleState[orderDetailsCliente.falabella_status]
                              }
                            >
                              {
                                translateStatesfalabbela[
                                  orderDetailsCliente.falabella_status
                                ]
                              }
                            </span>
                          ) : null}
                          {orderDetails.int_falabella_status ? (
                            <div>
                              <span>Estatus :</span>
                              <span
                                className={
                                  stlyleClassStates[
                                    orderDetails.int_falabella_status
                                  ]
                                }
                              >
                                {orderDetails.int_falabella_status !==
                                "isRequire"
                                  ? orderDetails.int_falabella_status
                                  : "Requiere Actualizacion "}
                              </span>
                            </div>
                          ) : null}
                        </div>
                      }
                    />

                    <CardContent>
                      <Box
                        sx={{
                          alignItems: "center",
                          display: "flex",
                          flexDirection: "column",
                          maxWidth: "350",
                        }}
                      >
                        <div>
                          <h1>Resumen</h1>
                        </div>
                        <Container className="formcontent">
                          <TabSecondary
                            props={props}
                            orderDetails={orderDetails}
                            orderDetailsCliente={orderDetailsCliente}
                            dataOrdesBicciExpress={dataOrdesBicciExpress}
                          />
                        </Container>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid.Column>

                <Grid.Column computer={6} mobile={16} tablet={16}>
                  <Card>
                    <CardContent>
                      {orderDetails && orderDetailsCliente ? (
                        <Button
                          onClick={() =>
                            HandlePrintBiccShipping(
                              orderDetails,
                              orderDetailsCliente
                            )
                          }
                          sx={{ width: "100%", fontSize: "1em" }}
                        >
                          {" "}
                          <PrintIcon fontSize="medium" />
                          Imprimir etiqueta
                        </Button>
                      ) : null}
                    </CardContent>
                  </Card>
                  <Card className="card-ultimasOrdenes typeMiniforms">
                    <CardHeader
                      title={
                        <h3>Comentarios visibles en manifiestos de rutas</h3>
                      }
                    />
                    <CardContent>
                      <Box
                        sx={{
                          alignItems: "center",
                          display: "flex",
                          flexDirection: "column",
                          maxWidth: "150",
                        }}
                      >
                        <Container className="formcontent">
                          <hr></hr>
                          <Typography
                            fontFamily="Lato, Arial, Helvetica, sans-serif"
                            color="textPrimary"
                            gutterBottom
                            variant="body"
                          >
                            Rider:{" "}
                          </Typography>
                          <Typography
                            fontFamily="Lato, Arial, Helvetica, sans-serif"
                            color="textPrimary"
                            gutterBottom
                            variant="body"
                          >
                            {orderDetails.observationReceptor ? (
                              <div className="color-blue">
                                {orderDetails.observationReceptor}
                              </div>
                            ) : (
                              "Sin Comentarios"
                            )}
                          </Typography>
                          <hr></hr>
                          Admin:
                          {orderDetails.observationAdmin &&
                          orderDetails.observationAdmin.length > 0
                            ? orderDetails.observationAdmin.map(
                                (item, index) => (
                                  <div key={index} className="color-blue">
                                    <hr></hr>
                                    <div>{item}</div>
                                  </div>
                                )
                              )
                            : " Sin Comentarios"}
                          <hr></hr>
                          Agregar comentario a la Orden
                          <div>
                            <Form onSubmit={saveComentAdmin}>
                              {" "}
                              <Form.Field
                                control={Input}
                                placeholder="Agregar comentario"
                                iconPosition="left"
                                type="text"
                                onChange={(e) => setNewComent(e.target.value)}
                                value={newComent}
                                icon="edit"
                              />
                              <Button
                                sx={{
                                  backgroundColor: "#591E8F",
                                  border: "none",
                                  borderRadius: "3px",
                                  color: "#fff",
                                  cursor: "pointer",
                                  fontSize: "14px",
                                  marginTop: "30px",
                                  outline: "none",
                                  padding: "5px",
                                  minWidth: "50px",
                                  fontWeight: 400,
                                  margin: "0em 1em 0em 0em",
                                  ":hover": {
                                    backgroundColor: "#25b2f4 !important",
                                    color: "#591e8f",
                                  },
                                }}
                                color="primary"
                                variant="contained"
                                type="sutmit"
                              >
                                Guardar Nota de Orden
                              </Button>
                            </Form>
                          </div>
                          <hr></hr>
                          <Button
                            sx={{
                              backgroundColor: "#591E8F",
                              border: "none",
                              borderRadius: "3px",
                              color: "#fff",
                              cursor: "pointer",
                              fontSize: "14px",
                              marginTop: "30px",
                              outline: "none",
                              padding: "5px",
                              minWidth: "50px",
                              fontWeight: 400,
                              margin: "0em 1em 0em 0em",
                              ":hover": {
                                backgroundColor: "#25b2f4 !important",
                                color: "#591e8f",
                              },
                            }}
                            onClick={() => setOpenEdit(true)}
                            color="primary"
                            variant="contained"
                            type="sutmit"
                          >
                            Enviar Excepción
                          </Button>
                          <Button
                            sx={{
                              backgroundColor: "#591E8F",
                              border: "none",
                              borderRadius: "3px",
                              color: "#fff",
                              cursor: "pointer",
                              fontSize: "14px",
                              marginTop: "30px",
                              outline: "none",
                              padding: "5px",
                              minWidth: "50px",
                              fontWeight: 400,
                              margin: "0em 1em 0em 0em",
                              ":hover": {
                                backgroundColor: "#25b2f4 !important",
                                color: "#591e8f",
                              },
                            }}
                            onClick={() => console.log("Asignar conductor")}
                            color="primary"
                            variant="contained"
                            type="sutmit"
                          >
                            Asignar conductor
                          </Button>
                          <Button
                            sx={{
                              backgroundColor: "#591E8F",
                              border: "none",
                              borderRadius: "3px",
                              color: "#fff",
                              cursor: "pointer",
                              fontSize: "14px",
                              marginTop: "30px",
                              outline: "none",
                              padding: "5px",
                              minWidth: "50px",
                              fontWeight: 400,
                              margin: "0em 1em 0em 0em",
                              ":hover": {
                                backgroundColor: "#25b2f4 !important",
                                color: "#591e8f",
                              },
                            }}
                            onClick={() =>
                              console.log("Editar Ruta o Posicion")
                            }
                            color="primary"
                            variant="contained"
                            type="sutmit"
                          >
                            Editar ruta o posición
                          </Button>
                          <ReusableFormExceptions
                            compyOrder={compyOrder}
                            open={openEdit}
                            handleClose={() => setOpenEdit(false)}
                            orderID={orderDetails.id}
                            riderDestails={
                              riderDestails?.driverLocation
                                ? riderDestails.driverLocation
                                : null
                            }
                            onSave={() => onSave()}
                          ></ReusableFormExceptions>
                          <hr></hr>
                          {/* <Button
                            sx={{
                              backgroundColor: "#591E8F",
                              border: "none",
                              borderRadius: "3px",
                              color: "#fff",
                              cursor: "pointer",
                              fontSize: "14px",
                              marginTop: "30px",
                              outline: "none",
                              padding: "5px",
                              minWidth: "50px",
                              fontWeight: 400,
                              margin: "0em 1em 0em 0em",
                              ":hover": {
                                backgroundColor: "#25b2f4 !important",
                                color: "#591e8f",
                              },
                            }}
                            onClick={() =>
                              (window.location.href = `https://bicci-7ed2f.firebaseapp.com/management/orders/${orderDetails.id}/summary`)
                            }
                            color="primary"
                            variant="contained"
                            type="sutmit"
                          >
                            Seleccionar Excepcion
                          </Button> */}
                        </Container>
                      </Box>
                    </CardContent>
                  </Card>
                  <Card className="card-ultimasOrdenes typeMiniforms">
                    <CardHeader title={<h3>Geo referencia</h3>} />
                    <CardContent>
                      <Box
                        sx={{
                          alignItems: "center",
                          display: "flex",
                          flexDirection: "column",
                          maxWidth: "350",
                        }}
                      >
                        <MapDeliveryPosition
                          markersPending={orderDetails}
                          dispachtHistory={dispachtHistory}
                          riderDestails={riderDestails}
                        ></MapDeliveryPosition>
                      </Box>
                    </CardContent>
                  </Card>
                  <Card className="card-ultimasOrdenes typeMiniforms">
                    <CardHeader title={<h3>Historial</h3>} />
                    <CardContent>
                      <Box
                        sx={{
                          alignItems: "center",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Container className="formcontent" id="micuentafromIni">
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>Fecha</TableCell>
                                <TableCell>Conductor</TableCell>
                                <TableCell>Estado</TableCell>
                                <TableCell>Integracion</TableCell>
                              </TableRow>
                            </TableHead>

                            {dispachtHistory.length > 0 ? (
                              <TableBody>
                                {dispachtHistory.map((item, index) => {
                                  // Buscar el conductor en el array de conductores por su ID

                                  const driver = ridersDataByCompany.find(
                                    (driver) => driver.id === item.driverID
                                  );
                                  if (driver) {
                                    latDriver = driver.name;
                                  }

                                  return (
                                    <TableRow key={index}>
                                      <TableCell>
                                        {format(
                                          item.createdAt.seconds * 1000,
                                          "dd/MM/yy HH:mm:ss"
                                        )}
                                      </TableCell>
                                      <TableCell>
                                        {/* Mostrar el nombre del conductor si se encuentra, de lo contrario mostrar "Desconocido" */}
                                        {driver
                                          ? driver.name
                                          : "Información de Sistema"}
                                      </TableCell>
                                      <TableCell>
                                        {
                                          translateStatesBicciFilter[
                                            item.status
                                          ]
                                        }
                                      </TableCell>
                                      <TableCell>
                                        {translateStatesfalabbela[item.status]}
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                              </TableBody>
                            ) : (
                              <TableBody>
                                <TableRow>
                                  <TableCell colSpan={3} align="center">
                                    No hay historial disponible
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            )}
                          </Table>
                        </Container>
                      </Box>
                    </CardContent>
                  </Card>

                  <Card className="card-ultimasOrdenes typeMiniforms">
                    <CardHeader title={<h3>Facturación</h3>} />
                    <CardContent>
                      <Box
                        sx={{
                          alignItems: "center",
                          display: "flex",
                          flexDirection: "column",
                          maxWidth: "350",
                        }}
                      >
                        <Container className="formcontent">
                          <hr></hr>
                          <Typography
                            fontFamily="Lato, Arial, Helvetica, sans-serif"
                            color="textPrimary"
                            gutterBottom
                            variant="body"
                          >
                            ID:
                          </Typography>
                          <Typography
                            fontFamily="Lato, Arial, Helvetica, sans-serif"
                            color="textPrimary"
                            gutterBottom
                            variant="body"
                          >
                            {orderDetails.id}
                          </Typography>
                          <hr></hr>
                          <Typography
                            fontFamily="Lato, Arial, Helvetica, sans-serif"
                            color="textPrimary"
                            gutterBottom
                            variant="body"
                          >
                            Nombre servicio:
                          </Typography>
                          <Typography
                            fontFamily="Lato, Arial, Helvetica, sans-serif"
                            color="textPrimary"
                            gutterBottom
                            variant="body"
                          >
                            {" "}
                            {orderDetails.deliveryType}
                          </Typography>
                          <hr></hr>
                          <Typography
                            fontFamily="Lato, Arial, Helvetica, sans-serif"
                            color="textPrimary"
                            gutterBottom
                            variant="body"
                          >
                            Costo de servicio:
                          </Typography>
                          <Typography
                            fontFamily="Lato, Arial, Helvetica, sans-serif"
                            color="textPrimary"
                            gutterBottom
                            variant="body"
                          >
                            _________
                          </Typography>
                          <hr></hr>
                        </Container>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        </>
      ) : (
        <>
          <Card>
            <CardHeader title={"Esperando resultados"}></CardHeader>
            <CardContent>
              <div className="card-body">
                <h5 className="card-title"></h5>
                {/* <p className="card-text">
                  <TableNoData
                    notFound={true}
                    bannerActive={false}
                    mensajeBanner={
                      "Código no coincide con un resultado de búsqueda, verifique el código. O consulte si la orden fue creada correctamente."
                    }
                  ></TableNoData>{" "}
                </p> */}
                {/* "Código no coincide con un resultado de búsqueda, verifique el
                código. O consulte si la orden fue creada correctamente." */}

                {loadAwait ? <CircularProgress></CircularProgress> : null}
                {findOrder ? (
                  "No se encontro información..."
                ) : (
                  <>
                    {" "}
                    {findOrder === false ? (
                      <>
                        {" "}
                        <p className="card-text">
                          No se encontraron resultados{" "}
                          <span
                            role="img"
                            aria-label="Nerd face"
                            className="bigFace"
                          >
                            😕
                          </span>{" "}
                          que coicidan con su busqueda, verifique el codigo. o
                          cunsulte si la orden fue creada correctamente <br />
                          <span
                            role="img"
                            aria-label="Nerd face"
                            className="bigFace"
                          >
                            🤓
                          </span>
                        </p>
                      </>
                    ) : (
                      "Buscando..."
                    )}
                  </>
                )}
                <hr></hr>
                <button
                  type="button"
                  className="btn btn-ebiex m-1"
                  onClick={handleClick}
                >
                  Regresar
                </button>
              </div>
            </CardContent>
          </Card>
        </>
      )}
    </>
  );
};

export default Dispatchdetails;
